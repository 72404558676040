import React from "react"
import { Link, graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allMarkdownRemark.edges

  console.log( data )

  return (
    <Layout location={location} title={siteTitle} logo={ data.logo.childImageSharp }>
      <SEO title="Computadoras singulares" />
      <Bio />
      { renderPosts( posts ) }
    </Layout>
  )
}

export default BlogIndex

function renderPosts( posts ) {
  let toPrint = [];

  posts.forEach(({ node }) => {
    console.log(node.fields);

    if( node.fields.slug === '/gamba/' ) return;

    
    const title = node.frontmatter.title || node.fields.slug;

    toPrint.push(
      <article key={node.fields.slug} class="articleListItem">
        <div class="articleItemThumb">
          <Link to={node.fields.slug} style={{ boxShadow: 'none' }}>
            <img src={`../../thumbs/${node.frontmatter.thumb}`}
              alt="Carátula del vídeo"
              style={{ width: '100%', marginBottom: 10 }}
            />
          </Link>
        </div>
        <div class="articleItemText">
          <header>
            <h3
              style={{
                fontFamily: `Montserrat, sans-serif`
              }}
            >
              <Link style={{ boxShadow: `none`, color: '#111' }} to={node.fields.slug}>
                {title}
              </Link>
            </h3>
            <small style={{ display: 'block', marginBottom: 10, marginTop: 5 }}>{node.frontmatter.date}</small>
          </header>
          <section>
            <p
              dangerouslySetInnerHTML={{
                __html: node.frontmatter.description || node.excerpt,
              }}
            />
          </section>

        </div>
      </article>
    );
  });

  return toPrint;
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY", locale: "ES")
            title
            description
            thumb
          }
        }
      }
    },
    logo: file(absolutePath: { regex: "/io-black.png/" }) {
      childImageSharp {
        fluid(maxWidth: 100, maxHeight: 200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
